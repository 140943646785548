import { template as template_d813896c1c1b4fc8a68a9af4691609ba } from "@ember/template-compiler";
const FKLabel = template_d813896c1c1b4fc8a68a9af4691609ba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
