import { template as template_bdf7af829dbf40d49e7065c6f7d3d6b9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_bdf7af829dbf40d49e7065c6f7d3d6b9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
