import { template as template_bc1edb028ce5455f8da0ffaddac1cb65 } from "@ember/template-compiler";
const WelcomeHeader = template_bc1edb028ce5455f8da0ffaddac1cb65(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
