import { template as template_1e763df12b394c5c91a64cbc00150ffb } from "@ember/template-compiler";
const FKText = template_1e763df12b394c5c91a64cbc00150ffb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
